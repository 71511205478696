import { useEffect, useState } from 'react';
import { useFlexgetAPI } from 'core/api';
export const useVersion = () => {
    const [version, setVersion] = useState();
    const [{ loading }, getVersion] = useFlexgetAPI('/server/version');
    useEffect(() => {
        const fn = async () => {
            const resp = await getVersion();
            if (resp.ok) {
                setVersion(resp.data);
            }
        };
        fn();
    }, [getVersion]);
    return { loading, version };
};
