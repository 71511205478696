export var SortByStatus;
(function (SortByStatus) {
    SortByStatus["LastExecutionTime"] = "last_execution_time";
    SortByStatus["ID"] = "id";
    SortByStatus["Name"] = "name";
    SortByStatus["Start"] = "start";
    SortByStatus["End"] = "end";
    SortByStatus["Produced"] = "produced";
    SortByStatus["Rejected"] = "rejected";
    SortByStatus["Accepted"] = "accepted";
    SortByStatus["Failed"] = "failed";
    SortByStatus["Succeeded"] = "succeeded";
    SortByStatus["AbortReason"] = "abort_reason";
})(SortByStatus || (SortByStatus = {}));
export var EventTypes;
(function (EventTypes) {
    EventTypes["Tasks"] = "tasks";
    EventTypes["Progress"] = "progress";
    EventTypes["EntryDump"] = "entry_dump";
    EventTypes["Summary"] = "summary";
    EventTypes["Clear"] = "clear";
    EventTypes["SetTask"] = "setTask";
})(EventTypes || (EventTypes = {}));
export var Status;
(function (Status) {
    Status["Pending"] = "pending";
    Status["Running"] = "running";
    Status["Complete"] = "complete";
})(Status || (Status = {}));
export var Phase;
(function (Phase) {
    Phase["Input"] = "input";
    Phase["Metainfo"] = "metainfo";
    Phase["Filter"] = "filter";
    Phase["Download"] = "download";
    Phase["Modify"] = "modify";
    Phase["Output"] = "output";
    Phase["Exit"] = "exit";
})(Phase || (Phase = {}));
