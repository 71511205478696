export var ServerOperation;
(function (ServerOperation) {
    ServerOperation["Reload"] = "reload";
    ServerOperation["Shutdown"] = "shutdown";
})(ServerOperation || (ServerOperation = {}));
export var DatabaseOperation;
(function (DatabaseOperation) {
    DatabaseOperation["Cleanup"] = "cleanup";
    DatabaseOperation["Vacuum"] = "vacuum";
    DatabaseOperation["PluginReset"] = "plugin_reset";
})(DatabaseOperation || (DatabaseOperation = {}));
