import { useCallback, useEffect, useState, useReducer } from 'react';
import { stringify } from 'qs';
import { createContainer } from 'unstated-next';
import { useFlexgetAPI, useFlexgetStream } from 'core/api';
import { Method, snakeCase, camelize } from 'utils/fetch';
import { action } from 'utils/hooks/actions';
import { EventTypes, } from './types';
export var Constants;
(function (Constants) {
    Constants["GET_TASKS"] = "@flexget/tasks/GET_TASKS";
})(Constants || (Constants = {}));
export const TaskContainer = createContainer(() => {
    const [tasks, setTasks] = useState([]);
    const [state, request] = useFlexgetAPI('/tasks');
    const refresh = useCallback(async () => {
        const resp = await request();
        if (resp.ok) {
            setTasks(resp.data);
        }
    }, [request]);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return { ...state, tasks, refresh };
});
export const useExecuteTask = () => {
    const [state, requestFn] = useFlexgetAPI('/tasks/execute', Method.Post);
    const request = useCallback((req) => requestFn(req), [requestFn]);
    return [state, request];
};
export const useGetTaskStatuses = (options) => {
    const [tasks, setTasks] = useState({ tasks: [], total: 0 });
    const query = stringify(snakeCase({ ...options, page: (options.page ?? 0) + 1 }));
    const [state, request] = useFlexgetAPI(`/tasks/status?${query}`);
    // const { tasks: configTasks } = useContainer(TaskContainer);
    // const { sortBy } = options;
    useEffect(() => {
        const fn = async () => {
            const resp = await request();
            if (resp.ok) {
                setTasks({ tasks: resp.data, total: parseInt(resp.headers.get('total-count') ?? '0', 10) });
            }
        };
        fn();
    }, [request]);
    // useMemo(() => {
    // const map = {};
    // const nt: Task[] = [...tasks];
    // tasks.tasks.forEach(t => {
    // map[t.id] = t;
    // });
    // configTasks.forEach(t => {
    // if (!map[t.id]) {
    // nt.push(t);
    // }
    // });
    // });
    return { ...state, ...tasks };
};
export const useGetTaskQueue = () => {
    const [tasks, setTasks] = useState([]);
    const [state, request] = useFlexgetAPI(`/tasks/queue`);
    useEffect(() => {
        const fn = async () => {
            const resp = await request();
            if (resp.ok) {
                setTasks(resp.data);
            }
        };
        fn();
    }, [request]);
    return { ...state, tasks };
};
export const useGetTask = (id) => {
    const [task, setTask] = useState();
    const [state, request] = useFlexgetAPI(`/tasks/status/${id}`);
    useEffect(() => {
        const fn = async () => {
            const resp = await request();
            if (resp.ok) {
                setTask(resp.data);
            }
        };
        fn();
    }, [request]);
    return { ...state, task };
};
export const useGetTaskExecutions = (id, options) => {
    const [executions, setExecutions] = useState({ executions: [], total: 0 });
    const query = stringify(snakeCase({ ...options, page: options.page + 1 }));
    const [state, request] = useFlexgetAPI(`/tasks/status/${id}/executions?${query}`);
    useEffect(() => {
        const fn = async () => {
            const resp = await request();
            if (resp.ok) {
                setExecutions({
                    executions: resp.data,
                    total: parseInt(resp.headers.get('total-count') ?? '0', 10),
                });
            }
        };
        fn();
    }, [request]);
    return { ...state, ...executions };
};
const actions = {
    tasks: (e) => action(EventTypes.Tasks, e),
    progress: (e) => action(EventTypes.Progress, e),
    entryDump: (e) => action(EventTypes.EntryDump, e),
    summary: (e) => action(EventTypes.Summary, e),
    clear: () => action(EventTypes.Clear),
    setTask: (id) => action(EventTypes.SetTask, id),
};
const taskReducer = (state, act) => {
    switch (act.type) {
        case EventTypes.Tasks:
            return {
                tasks: act.payload.tasks.reduce((ts, task) => ({
                    ...ts,
                    [task.id]: {
                        ...task,
                        progress: [],
                    },
                }), {}),
                selectedTask: act.payload.tasks[0].id,
            };
        case EventTypes.Progress: {
            const e = act.payload;
            const task = state.tasks[e.taskId];
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [task.id]: {
                        ...task,
                        progress: [e.progress, ...task.progress],
                    },
                },
            };
        }
        case EventTypes.EntryDump: {
            const e = act.payload;
            const task = state.tasks[e.taskId];
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [task.id]: {
                        ...task,
                        entries: e.entryDump,
                    },
                },
            };
        }
        case EventTypes.Summary: {
            const e = act.payload;
            const task = state[e.taskId];
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [task.id]: {
                        ...task,
                        summary: e.summary,
                    },
                },
            };
        }
        case EventTypes.SetTask:
            return {
                ...state,
                selectedTask: act.payload,
            };
        case EventTypes.Clear:
            return {
                tasks: {},
            };
        default:
            return state;
    }
};
export const useExecuteTaskStream = () => {
    const [{ readyState, stream }, { connect, disconnect }] = useFlexgetStream('/tasks/execute', Method.Post);
    const [state, dispatch] = useReducer(taskReducer, {
        tasks: {},
    });
    const execute = useCallback((body) => {
        disconnect();
        dispatch(actions.clear());
        connect(body);
    }, [connect, disconnect]);
    useEffect(() => disconnect, [disconnect]);
    useEffect(() => {
        stream
            ?.node('{tasks}', (e) => dispatch(actions.tasks(camelize(e))))
            .node('{progress}', (e) => dispatch(actions.progress(camelize(e))))
            .node('{entry_dump}', (e) => dispatch(actions.entryDump(camelize(e))))
            .node('{summary}', (e) => dispatch(actions.entryDump(camelize(e))));
    }, [stream]);
    const setTask = useCallback((_, id) => dispatch(actions.setTask(id)), []);
    return [
        { state, readyState },
        { execute, setTask },
    ];
};
