import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import { AuthContainer } from 'core/auth/hooks';
const PrivateRoute = ({ component: Component, ...rest }) => {
    const [loggedIn] = useContainer(AuthContainer);
    return (<Route {...rest} render={props => loggedIn ? (<Component {...props}/>) : (<Redirect to={{
        pathname: '/login',
        state: { from: props.location },
    }}/>)}/>);
};
export default PrivateRoute;
