import { useEffect, useState, useCallback } from 'react';
import { useFlexgetAPI } from 'core/api';
import { useGlobalInfo } from 'core/status/hooks';
import { Method } from 'utils/fetch';
export const useServerOperation = (operation, onSuccess = () => { }) => {
    const [state, request] = useFlexgetAPI('/server/manage', Method.Post);
    const makeRequest = useCallback(async () => {
        const resp = await request({ operation });
        if (resp.ok) {
            onSuccess();
        }
    }, [onSuccess, operation, request]);
    return [state, makeRequest];
};
export const useGetPlugins = () => {
    const [plugins, setPlugins] = useState([]);
    const [{ loading }, getPlugins] = useFlexgetAPI('/database/plugins');
    useEffect(() => {
        const fn = async () => {
            const resp = await getPlugins();
            if (resp.ok) {
                setPlugins(resp.data);
            }
        };
        fn();
    }, [getPlugins]);
    return { loading, plugins };
};
export const useDBOperation = () => {
    const [state, request] = useFlexgetAPI('/database', Method.Post);
    const pushInfo = useGlobalInfo();
    const performOperation = useCallback(async (req) => {
        const resp = await request(req);
        if (resp.ok) {
            pushInfo(resp.data.message);
        }
    }, [pushInfo, request]);
    return [state, performOperation];
};
