import * as humps from 'humps';
export class StatusError extends Error {
    constructor(message = 'Error', s) {
        super(message);
        this.status = s;
    }
}
export var Method;
(function (Method) {
    Method["Get"] = "get";
    Method["Post"] = "post";
    Method["Put"] = "put";
    Method["Patch"] = "patch";
    Method["Delete"] = "delete";
})(Method || (Method = {}));
export const camelize = (obj) => humps.camelizeKeys(obj, {
    separator: '_',
});
export const snakeCase = (obj) => humps.decamelizeKeys(obj, {
    split: /(?=[A-Z])/,
});
const status = async (r, skipCamelize = false) => {
    const response = r;
    try {
        response.data = await response.json();
    }
    catch (err) {
        // do nothing for nwo;
    }
    if (response.ok) {
        response.data = response.data && !skipCamelize ? camelize(response.data) : response.data;
    }
    else {
        response.error = new StatusError(response.data?.message, response.status);
    }
    return response;
};
export const request = async (resource, method, rawBody, opts = {}, skipCamelize = false) => {
    const options = { method };
    const headers = {
        ...opts.headers,
        Accept: 'application/json',
    };
    if (method !== Method.Get && rawBody) {
        headers['Content-Type'] = 'application/json';
    }
    options.body = rawBody ? JSON.stringify(snakeCase(rawBody)) : undefined;
    options.credentials = 'same-origin';
    const response = await fetch(resource, { ...options, ...opts, headers });
    return status(response, skipCamelize);
};
