import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
export const colorClass = (theme) => css `
  color: ${theme.palette.secondary.light};
`;
const navItem = (theme) => css `
  ${colorClass(theme)};
  border-left: 3px solid transparent;
  cursor: pointer;

  &:hover {
    border-left: 3px solid ${theme.palette.primary.main};
  }
`;
const SideNavEntry = ({ onClick, path, Icon, name, className }) => {
    const item = (<ListItem css={theme => [navItem(theme), className]} onClick={onClick}>
      <ListItemIcon css={colorClass}>
        <Icon />
      </ListItemIcon>
      <ListItemText css={colorClass} disableTypography primary={name}/>
    </ListItem>);
    if (path) {
        return <Link to={path}>{item}</Link>;
    }
    return item;
};
export default SideNavEntry;
