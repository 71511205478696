import { useCallback, useReducer, useEffect, useRef } from 'react';
import { createContainer, useContainer } from 'unstated-next';
import uuid from 'uuid/v4';
import { action } from 'utils/hooks/actions';
var Constants;
(function (Constants) {
    Constants["SET_LOADING"] = "@flexget/status/SET_LOADING";
    Constants["PUSH_ERROR"] = "@flexget/status/PUSH_ERROR";
    Constants["POP_ERROR"] = "@flexget/status/POP_ERROR";
    Constants["PUSH_INFO"] = "@flexget/status/PUSH_INFO";
    Constants["POP_INFO"] = "@flexget/status/POP_INFO";
    Constants["CLEAR"] = "@flexget/status/CLEAR";
})(Constants || (Constants = {}));
export const actions = {
    setLoading: (id, loading) => action(Constants.SET_LOADING, { [id]: loading }),
    pushError: (error) => action(Constants.PUSH_ERROR, error),
    popError: () => action(Constants.POP_ERROR),
    pushInfo: (message) => action(Constants.PUSH_INFO, message),
    popInfo: () => action(Constants.POP_INFO),
    clear: () => action(Constants.CLEAR),
};
const initState = {
    loading: {},
    info: [],
    errors: [],
};
const statusReducer = (state, act) => {
    switch (act.type) {
        case Constants.SET_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ...act.payload,
                },
            };
        case Constants.PUSH_ERROR:
            return {
                ...state,
                errors: [...state.errors, act.payload],
            };
        case Constants.POP_ERROR:
            return {
                ...state,
                errors: state.errors.slice(1),
            };
        case Constants.PUSH_INFO:
            return {
                ...state,
                info: [...state.info, act.payload],
            };
        case Constants.POP_INFO:
            return {
                ...state,
                info: state.info.slice(1),
            };
        case Constants.CLEAR:
            return initState;
        default:
            return state;
    }
};
export const StatusContainer = createContainer(() => {
    return useReducer(statusReducer, initState);
});
export const useGlobalStatus = (loading, error) => {
    const id = useRef(uuid());
    const [, dispatch] = useContainer(StatusContainer);
    useEffect(() => {
        if (error) {
            dispatch(actions.pushError(error));
        }
        dispatch(actions.setLoading(id.current, loading));
    }, [dispatch, error, loading]);
};
export const useGlobalInfo = () => {
    const [, dispatch] = useContainer(StatusContainer);
    return useCallback((message) => dispatch(actions.pushInfo(message)), [dispatch]);
};
