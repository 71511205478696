import { useState, useCallback, useReducer, useEffect } from 'react';
import { useFormikContext } from 'formik';
export const useOverlayState = (defaultState = false) => {
    const [isOpen, setOpen] = useState(defaultState);
    const open = useCallback(() => setOpen(true), []);
    const close = useCallback(() => setOpen(false), []);
    const toggle = useCallback(() => setOpen(o => !o), []);
    return [isOpen, { open, close, toggle }];
};
export const useMergeState = (defaultState) => {
    return useReducer((state, action) => ({
        ...state,
        ...action,
    }), defaultState);
};
export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);
    return debouncedValue;
};
export const useDebounceFormikSubmit = (delay) => {
    const { values, submitForm } = useFormikContext();
    const debouncedValues = useDebounce(values, delay);
    useEffect(() => {
        submitForm();
    }, [...Object.values(debouncedValues), submitForm]); // eslint-disable-line react-hooks/exhaustive-deps
};
