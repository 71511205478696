var Events;
(function (Events) {
    Events["RegisterPlugin"] = "register";
    Events["UnregisterPlugin"] = "unregister";
    Events["Update"] = "update";
})(Events || (Events = {}));
export class PluginRegistry {
    constructor() {
        this._plugins = {};
        document.addEventListener(Events.RegisterPlugin, (e) => {
            this.registerPlugin(e.detail.path, e.detail.plugin);
        });
    }
    get plugins() {
        return this._plugins;
    }
    set plugins(plugins) {
        this._plugins = plugins;
        document.dispatchEvent(new CustomEvent(Events.Update, { detail: plugins }));
    }
    registerPlugin(path, plugin) {
        if (!path) {
            throw Error('Plugin requires path');
        }
        if (!plugin.displayName || !plugin.icon) {
            throw Error('Plugin requires displayName and icon');
        }
        this.plugins = { ...this.plugins, [path]: plugin };
    }
}
const registry = new PluginRegistry();
export const subscribe = (fn) => {
    const handler = (e) => {
        fn(e.detail);
    };
    // Make an inital call incase all plugins have already been registered
    fn(registry.plugins);
    document.addEventListener(Events.Update, handler);
    return () => document.removeEventListener(Events.Update, handler);
};
export const registerPlugin = (path, plugin) => {
    const event = new CustomEvent(Events.RegisterPlugin, {
        detail: {
            path,
            plugin,
        },
    });
    document.dispatchEvent(event);
};
window.registerFlexgetPlugin = registerPlugin;
window.subscribeFlexgetPlugins = subscribe;
