import { useEffect, useRef, useCallback, useState } from 'react';
import { Method, request, snakeCase } from 'utils/fetch';
import { AuthContainer } from 'core/auth/hooks';
import { uriParser } from 'utils';
import { useContainer } from 'unstated-next';
import oboe from 'oboe';
export function useFlexgetAPI(url, method = Method.Get, skipCamelize = false) {
    const [, setLoggedIn] = useContainer(AuthContainer);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const controller = useRef(new AbortController());
    const baseURI = useRef(uriParser(document.baseURI));
    const requestFn = useCallback(async (body = undefined) => {
        try {
            setLoading(true);
            const payload = await request(`${baseURI.current.pathname}api${url}`, method, body, { signal: controller.current.signal }, skipCamelize);
            if (payload.status === 401) {
                setLoggedIn(false);
            }
            setLoading(false);
            if (!payload.ok) {
                setError(payload.error);
            }
            return payload;
        }
        catch (err) {
            return { ok: false, error: err, data: err };
        }
    }, [method, setLoggedIn, skipCamelize, url]);
    useEffect(() => () => {
        controller.current.abort();
    }, []);
    return [{ loading, error }, requestFn];
}
export var ReadyState;
(function (ReadyState) {
    ReadyState[ReadyState["Closed"] = 0] = "Closed";
    ReadyState[ReadyState["Connecting"] = 1] = "Connecting";
    ReadyState[ReadyState["Open"] = 2] = "Open";
})(ReadyState || (ReadyState = {}));
export const useFlexgetStream = (url, method = Method.Get) => {
    const [readyState, setReadyState] = useState(ReadyState.Closed);
    const [stream, setStream] = useState();
    const baseURI = useRef(uriParser(document.baseURI));
    const connect = useCallback((body) => {
        setReadyState(ReadyState.Connecting);
        setStream(oboe({
            url: `${baseURI.current.pathname}api${url}`,
            method,
            body: body ? snakeCase(body) : body,
        })
            .start(() => setReadyState(ReadyState.Open))
            .fail(() => setReadyState(ReadyState.Closed)));
    }, [method, url]);
    const disconnect = useCallback(() => {
        setReadyState(ReadyState.Closed);
        setStream(s => {
            s?.abort();
            return undefined;
        });
    }, []);
    return [
        { stream, readyState },
        { connect, disconnect },
    ];
};
