import { useState, useEffect, useCallback } from 'react';
import { createContainer, useContainer } from 'unstated-next';
const defaultTitle = 'Flexget Manager';
export const AppBarContainer = createContainer(() => {
    const [title, setTitle] = useState(defaultTitle);
    const [content, setContent] = useState();
    const [menuProps, setMenuProps] = useState();
    const [contextualProps, setContextualProps] = useState();
    const [contextualMode, setContextual] = useState(false);
    const [icon, setIcon] = useState();
    return [
        { title, content, contextualProps, contextualMode, menuProps, icon },
        { setTitle, setContent, setContextualProps, setContextual, setMenuProps, setIcon },
    ];
});
export const useInjectPageTitle = (title) => {
    const [, { setTitle }] = useContainer(AppBarContainer);
    useEffect(() => {
        setTitle(title);
        document.title = `${defaultTitle} - ${title}`;
    }, [setTitle, title]);
};
export const useInjectContent = (content) => {
    const [, { setContent }] = useContainer(AppBarContainer);
    useEffect(() => {
        setContent(content);
        return () => setContent(undefined);
    }, [content, setContent]);
};
export const useSetMenuProps = (menuProps) => {
    const [, { setMenuProps }] = useContainer(AppBarContainer);
    useEffect(() => {
        setMenuProps(menuProps);
        return () => setMenuProps(undefined);
    }, [menuProps, setMenuProps]);
};
export const useContextualAppBar = (props) => {
    const [, { setContextual, setContextualProps }] = useContainer(AppBarContainer);
    const clear = useCallback(() => {
        setContextualProps(undefined);
        setContextual(false);
    }, [setContextual, setContextualProps]);
    useEffect(() => {
        setContextualProps(props);
        return clear;
    }, [clear, props, setContextualProps]);
    return { setContextual, clear };
};
export const useSetAppBarIcon = (icon) => {
    const [, { setIcon }] = useContainer(AppBarContainer);
    useEffect(() => {
        setIcon(icon);
        return () => setIcon(undefined);
    }, [icon, setIcon]);
};
